<template >
  <v-container class="py-10">
    <v-row justify="space-between">
      <v-col md="8" class="me-auto text-start">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          ЭЕШ анализ
        </h5>
        <p class="text-body">
          Сорилуудын нийт дундаж, өсөлтийн үзүүлэлт
        </p>
      </v-col>
    </v-row>
    <section v-if="loading == false">
      <v-data-table
        v-if="lessonCategories && maxLength"
        :headers="headers"
        :items="lessonCategories"
        :items-per-page="-1"
        class="elevation-1"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <th style="width: 1%">
              {{ props.item.index }}
            </th>
            <th style="width: 20%">
              {{ props.item.name }}
            </th>
            <th v-for="testIndex in max" :key="'test' + testIndex">
              {{ _getTest(props.item, testIndex) }}
            </th>
          </tr>
        </template>
      </v-data-table>
    </section>
    <section v-else class="d-flex flex-row justify-center">
      <!-- <v-progress-circular
        :rotate="360"
        :size="100"
        :width="15"
        :model-value="loadingStatus"
        color="red"
        class="mt-16"
      >
        {{ loadingStatus }}%
      </v-progress-circular> -->
      <v-progress-circular
        :size="70"
        :width="7"
        color="red"
        class="mt-16"
        indeterminate
      ></v-progress-circular>
    </section>
  </v-container>
</template>
  
  <script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      lessonCategories: null,
      tests: null,
      headers: [
        { text: "No", value: "index", width: "1%" },
        { text: "Xичээл", value: "name", width: "20%" },
      ],
      maxLength: null,
      max: 0,
      loading: true,
      loadingStatus: 0,
    };
  },
  watch: {
    loading(val) {
      if (val == false) {
        var i = 1;
        this.max = this.getLength();
        while (i <= this.max) {
          this.headers.push({ text: "Сорил-" + i });
          i++;
        }
      }
    },
  },
  methods: {
    getLength() {
      let arr = Object.values(this.maxLength);
      let max = Math.max(...arr);
      console.log(max);
      return max;
    },
    _getTest(category, testIndex) {
      console.log(this.tests);
      var found = null;
      found = this.tests.find(
        (test) =>
          test.index == testIndex && test.categoryRef&& test.categoryRef.path == category.ref.path
      );

      if (found) {
        return ((found.totalScore / found.finalScore) * 100).toFixed(2) + "%";
      } else {
        return "-";
      }
    },
    async _setupp() {
      if (fb.EYESH_APP) {
        fb.db
          .collection("assignmentsdatabase")
          .where("createdByEmail", "==", "granditxxk@gmail.com")
          .where("deleted", "==", false)
          .orderBy("createdAt", "asc")
          .get()
          .then(async (docs) => {
            this.tests = [];
            var counter = 0;
            docs.forEach(async (doc) => {
              let assignment = doc.data();
              assignment.ref = doc.ref;
              assignment.id = doc.id;
              if (assignment.categoryRef) {
                if (this.maxLength[assignment.categoryRef.id])
                  this.maxLength[assignment.categoryRef.id] =
                    this.maxLength[assignment.categoryRef.id] + 1;
                else this.maxLength[assignment.categoryRef.id] = 1;
                assignment.index = this.maxLength[assignment.categoryRef.id];
              }

              var totalScore = 0;
              if (this.userData.school) {
                await doc.ref
                  .collection("results-simplified")
                  .where("schoolRef", "==", this.userData.school.ref)
                  .get()
                  .then((xdocs) => {
                    console.log(xdocs);
                    if (!xdocs.empty) {
                      xdocs.forEach((xdoc) => {
                        totalScore =
                          totalScore + Number(xdoc.data().totalScore);
                      });

                      assignment.totalScore = (totalScore / xdocs.size).toFixed(
                        2
                      );
                      assignment.totalParticipants = xdocs.size;
                    } else {
                      assignment.totalScore = 0;
                      assignment.totalParticipants = 0;
                    }
                  });
              } else {
                await doc.ref
                  .collection("results-simplified")
                  .get()
                  .then((xdocs) => {
                    if (!xdocs.empty) {
                      xdocs.forEach((xdoc) => {
                        totalScore =
                          totalScore + Number(xdoc.data().totalScore);
                      });

                      assignment.totalScore = (totalScore / xdocs.size).toFixed(
                        2
                      );
                      assignment.totalParticipants = xdocs.size;
                    } else {
                      assignment.totalScore = 0;
                      assignment.totalParticipants = 0;
                    }
                  });
              }

              this.tests.push(assignment);
              counter++;
              console.log(docs.size, counter);
              this.loadingStatus = ((counter / docs.size) * 100).toFixed(0); //LOADING BAR HERE
              if (docs.size == counter) {
                this.loading = false;
              }
            });
          });
        console.log(this.maxLength);
      } else {
        fb.dbeyesh800
          .collection("assignmentsdatabase")
          .where("createdByEmail", "==", "granditxxk@gmail.com")
          .where("deleted", "==", false)
          .orderBy("createdAt", "asc")
          .get()
          .then(async (docs) => {
            this.tests = [];
            var counter = 0;
            docs.forEach(async (doc) => {
              let assignment = doc.data();
              assignment.ref = doc.ref;
              assignment.id = doc.id;
              if (assignment.categoryRef) {
                if (this.maxLength[assignment.categoryRef.id])
                  this.maxLength[assignment.categoryRef.id] =
                    this.maxLength[assignment.categoryRef.id] + 1;
                else this.maxLength[assignment.categoryRef.id] = 1;
                assignment.index = this.maxLength[assignment.categoryRef.id];
              }

              var totalScore = 0;
              if (this.userData.school) {
                await doc.ref
                  .collection("results-simplified")
                  .where("schoolRef", "==", this.userData.school.ref.path)
                  .get()
                  .then((xdocs) => {
                    console.log(xdocs);
                    if (!xdocs.empty) {
                      xdocs.forEach((xdoc) => {
                        totalScore =
                          totalScore + Number(xdoc.data().totalScore);
                      });

                      assignment.totalScore = (totalScore / xdocs.size).toFixed(
                        2
                      );
                      assignment.totalParticipants = xdocs.size;
                    } else {
                      assignment.totalScore = 0;
                      assignment.totalParticipants = 0;
                    }
                  });
              } else {
                await doc.ref
                  .collection("results-simplified")
                  .get()
                  .then((xdocs) => {
                    if (!xdocs.empty) {
                      xdocs.forEach((xdoc) => {
                        totalScore =
                          totalScore + Number(xdoc.data().totalScore);
                      });

                      assignment.totalScore = (totalScore / xdocs.size).toFixed(
                        2
                      );
                      assignment.totalParticipants = xdocs.size;
                      console.log(assignment.totalScore);
                    } else {
                      assignment.totalScore = 0;
                      assignment.totalParticipants = 0;
                    }
                  });
              }

              this.tests.push(assignment);
              counter++;
              console.log(docs.size, counter);
              this.loadingStatus = ((counter / docs.size) * 100).toFixed(0); //LOADING BAR HERE
              if (docs.size == counter) {
                this.loading = false;
              }
            });
          });
        console.log(this.maxLength);
      }
    },
  },

  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },

  created() {
    this.loading = true;
    if (fb.EYESH_APP) {
      fb.db
        .collection("categories")
        .orderBy("createdAt", "asc")
        .get()
        .then((querySnapshot) => {
          var counter = 0;
          this.lessonCategories = [];
          this.maxLength = {};

          querySnapshot.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;

            lesson.name2 =
              ++counter +
              ". " +
              lesson.name +
              " (" +
              lesson.lessonTypeName +
              ") ";
            this.maxLength[lesson.id] = 0;
            lesson.index = counter;
            this.lessonCategories.push(lesson);
          });
        })
        .then(() => {
          this._setupp();
        });
    } else {
      fb.dbeyesh800
        .collection("categories")
        .orderBy("createdAt", "asc")
        .get()
        .then((querySnapshot) => {
          var counter = 0;
          this.lessonCategories = [];
          this.maxLength = {};

          querySnapshot.forEach((doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;

            lesson.name2 =
              ++counter +
              ". " +
              lesson.name +
              " (" +
              lesson.lessonTypeName +
              ") ";
            this.maxLength[lesson.id] = 0;
            lesson.index = counter;
            this.lessonCategories.push(lesson);
          });
        })
        .then(() => {
          this._setupp();
        });
    }
  },
};
</script>
  
  